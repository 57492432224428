import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
            <Helmet>
        <meta charSet="utf-8" />
        <title>State Legislature Maps</title>
        <meta name="description" content="Brought to you by North America's only cartographer to stand for federal office and lose" />
        <meta name="image" content="https://awmcphee.ca/coos.png" />
        <meta property="og:image" content="https://awmcphee.ca/coos.png"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@ksituan"/>
        <meta name="twitter:title" content="State Legislature Maps"/>
        <meta name="twitter:description" content="Brought to you by North America's only cartographer to stand for federal office and lose"/>
        <meta name="twitter:image" content="https://awmcphee.ca/coos.png"/>
      </Helmet>

      <h1>State Legislatures</h1>
      <h2>The Complete Lower Houses of the United States</h2>

      <p><strong>Coming soon</strong> - all these products are currently in development.
      Already know you want one?
      Punch your favorite state into <a href="https://forms.gle/UmEfJHAYZVWLr4iG9" target="_blank" rel="noopener noreferrer">this contact form</a> - every response makes the maps come out faster!</p>

      <a className="mapLink bigSask" href="/states.png" target="_blank" rel="noopener noreferrer">
        <StaticImage className="map linkedMap" src="../images/states.png" alt="Complete map of all 50 state legislatures"/>
        <div className="mapNote width">← A truly ridiculous 120 inches wide (ships in two parts) →</div>
        <div className="mapNote height">← 36 inches tall →</div>
        <div className="mapNote note">Click map to open preview in new tab. (5MB)</div>
      </a>

      <p>Do you work with state representatives?
        This mighty map is a work in progress with one goal: show every state legislature at a glance on one page.
        I hold multiple industry awards in cartographic design, and my election maps elegantly visualize odd errata that other maps struggle with - all in colorblind-accessible format.
      </p>

      <p>The Complete Lower Houses will easily fill an entire wall upon completion, and will retail at <strong>$499</strong>. New editions will be available with annual update frequency.</p>

      <h2>State Legislature Maps</h2>

      <StaticImage className="map" src="../images/texas.png" alt="Map of the Texas House of Representatives."/>

      <p>The perfect gift for the political buff or minor elected official in your life.</p>

      <h2>Historical maps</h2>

      <p>I have been interested in mapping state legislatures for a while. Here are some past projects, now out-of-date.</p>

      <div className="portfolio">
      <div className="pf">
          <StaticImage
            src="../portfolio/alabama.png"
            alt="A political map of Alabama."
          />
          <h4>Alabama</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/alaska.png"
            alt="A political map of Alaska."
          />
          <h4>Alaska</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/arizona.png"
            alt="A political map of Arizona."
          />
          <h4>Arizona</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/arkansas.png"
            alt="A political map of Arkansas."
          />
          <h4>Arkansas</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/california.png"
            alt="A political map of California."
          />
          <h4>California</h4>
        </div>
          <div className="pf">
          <StaticImage
            src="../portfolio/colorado.png"
            alt="A political map of Colorado."
          />
          <h4>Colorado</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/connecticut.png"
            alt="A political map of Connecticut."
          />
          <h4>Connecticut</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/delaware.png"
            alt="A political map of Delaware."
          />
          <h4>Delaware</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/florida.png"
            alt="A political map of Florida."
          />
          <h4>Florida</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/georgia.png"
            alt="A political map of Georgia."
          />
          <h4>Georgia</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/hawaii.png"
            alt="A political map of Hawaii."
          />
          <h4>Hawaii</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/idaho.png"
            alt="A political map of Idaho."
          />
          <h4>Idaho</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/illinois.png"
            alt="A political map of Illinois."
          />
          <h4>Illinois</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/indiana.png"
            alt="A political map of Indiana."
          />
          <h4>Indiana</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/iowa.png"
            alt="A political map of Iowa."
          />
          <h4>Iowa</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/kansas.png"
            alt="A political map of Kansas."
          />
          <h4>Kansas</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/kentucky.png"
            alt="A political map of Kentucky."
          />
          <h4>Kentucky</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/louisiana.png"
            alt="A political map of Louisiana."
          />
          <h4>Louisiana</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/maine.png"
            alt="A political map of Maine."
          />
          <h4>Maine</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/maryland.png"
            alt="A political map of Maryland."
          />
          <h4>Maryland</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/massachusetts.png"
            alt="A political map of Massachusetts."
          />
          <h4>Massachusetts</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/michigan.png"
            alt="A political map of Michigan."
          />
          <h4>Michigan</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/minnesota.png"
            alt="A political map of Minnesota."
          />
          <h4>Minnesota</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/mississippi.png"
            alt="A political map of Mississippi."
          />
          <h4>Mississippi</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/missouri.png"
            alt="A political map of Missouri."
          />
          <h4>Missouri</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/montana.png"
            alt="A political map of Montana."
          />
          <h4>Montana</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/nebraska.png"
            alt="A political map of Nebraska."
          />
          <h4>Nebraska</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/nevada.png"
            alt="A political map of Nevada."
          />
          <h4>Nevada</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/new_hampshire.png"
            alt="A political map of New Hampshire."
          />
          <h4>New Hampshire</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/new_jersey.png"
            alt="A political map of New Jersey."
          />
          <h4>New Jersey</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/new_mexico.png"
            alt="A political map of New Mexico."
          />
          <h4>New Mexico</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/new_york.png"
            alt="A political map of New York."
          />
          <h4>New York</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/north_carolina.png"
            alt="A political map of North Carolina."
          />
          <h4>North Carolina</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/north_dakota.png"
            alt="A political map of North Dakota."
          />
          <h4>North Dakota</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/ohio.png"
            alt="A political map of Ohio."
          />
          <h4>Ohio</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/oklahoma.png"
            alt="A political map of Oklahoma."
          />
          <h4>Oklahoma</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/oregon.png"
            alt="A political map of Oregon."
          />
          <h4>Oregon</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/pennsylvania.png"
            alt="A political map of Pennsylvania."
          />
          <h4>Pennsylvania</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/rhode_island.png"
            alt="A political map of Rhode Island."
          />
          <h4>Rhode Island</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/south_carolina.png"
            alt="A political map of South Carolina."
          />
          <h4>South Carolina</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/south_dakota.png"
            alt="A political map of South Dakota."
          />
          <h4>South Dakota</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/tennessee.png"
            alt="A political map of Tennessee."
          />
          <h4>Tennessee</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/texas.png"
            alt="A political map of Texas."
          />
          <h4>Texas</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/utah.png"
            alt="A political map of Utah."
          />
          <h4>Utah</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/vermont.png"
            alt="A political map of Vermont."
          />
          <h4>Vermont</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/virginia.png"
            alt="A political map of Virginia."
          />
          <h4>Virginia</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/washington.png"
            alt="A political map of Washington."
          />
          <h4>Washington</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/west_virginia.png"
            alt="A political map of West Virginia."
          />
          <h4>West Virginia</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/wisconsin.png"
            alt="A political map of Wisconsin."
          />
          <h4>Wisconsin</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/wyoming.png"
            alt="A political map of Wyoming."
          />
          <h4>Wyoming</h4>
        </div>
        <div className="pf">
          <StaticImage
            src="../portfolio/puerto_rico.png"
            alt="A political map of Puerto Rico."
          />
          <h4>Puerto Rico</h4>
        </div>
      </div>
    </Layout>
  );
}
